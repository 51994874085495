body {
    margin: 0;
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Color Palette 

Gammelt skrot:
Peach:      FF604A
Light blue: 1CC8FF
Pinkish:    FF2E67
Blue:       436DFF
Dark-blue:  131425
Redish:     FF3857
Dark-green: 123123
Orangeish:  FF963A
Blå:      123063

Farge 1:  0071ce
Farge 2:  FF604A
Farge 3:  0e9bca
Farge 4:  FF963A
Farge 5:  0084b8
Farge 6:  0d7227
Farge 7:  0026ce
Farge 8:  da007f
Farge 9:  ce0056
Farge 10: 6814ee
Farge 11: 04803c
Farge 12: 1a398d
Farge 13: 701639
Farge 14: 118e9e
Farge 15: ee9714
Farge 16: 2f57c5

Kul lys grønn-blå: 1ce698

Blåfarger basert på 14: 137899 1089b1 117d9e 199eaf 0090a3

Forrige grønne: 04803c
Forrige blå blå:  0071ce (main) | 005eac (slightly darker)
Foreløpig blå: 137899 (main) | 116e8d (slightly darker)
Header:         131316
*/


/* Other */

.svg {
    width: 100%;
    height: 100%;
}

.viewContainer {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}


/* Header */

.defaultHeaderSection {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    position: fixed;
    margin: auto;
    z-index: 20;
    background-color: #ffffff;
    transition: 400ms;
}

.otherHeaderSection {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    position: fixed;
    margin: auto;
    z-index: 20;
    background-color: #131316;
    transition: 400ms;
}

.defaultHeader {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 60px;
    margin: auto;
    z-index: 20;
    background-color: #ffffff;
    max-width: 1300px;
    transition: 400ms;
}

.otherHeader {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 50px;
    margin: auto;
    z-index: 20;
    background-color: #131316;
    max-width: 1300px;
    transition: 400ms;
}

.logo {
    width: 175px;
    margin: auto;
}

.mobileLogo {
    width: 175px;
    margin-left: 30px;
    margin-top: 15px;
}

.tabs {
    margin: auto;
    display: flex;
    flex-direction: row;
    width: 60%;
    max-width: 650px;
}

.tab {
    margin: auto;
}

.cornerTab {
    margin: auto;
    width: 175px;
    text-align: center;
}

.emptyTabLeft {
    width: 10%;
}

.emptyTabRight {
    width: 10%;
}

.defaultTabLink {
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #2c2c2c;
    text-decoration: none;
    transition: 400ms;
}

.defaultTabLink:hover {
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color: #000000;
    text-decoration: underline 1.5px #3c3c3c;
    transition: 400ms;
}

.otherTabLink {
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #d8d8d8;
    text-decoration: none;
}

.otherTabLink:hover {
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color: #FFFFFF;
    text-decoration: underline 1px #aaaaaa;
}


.menuIcon {
    position: absolute;
    right: 5%;
    margin-top: 7px;
}

.menuIcon:hover {
    cursor: pointer;
}

.menuSection {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 15;
    background-color: #131316;
    margin: auto;
}

.menuTabs {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 120px;
}

.menuTab {
    position: relative;
    margin: auto;
    margin-top: 24px;
}

.menuTabLink {
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #d8d8d8;
    text-decoration: none;
}

.menuTabLink:hover {
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color: #FFFFFF;
    text-decoration: none;
}



/* Footer */

.footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #131316;
    z-index: 1;
    font-weight: 300;
}

.footerCompany {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.companyText {
    color: #ffffffd6;
    font-size: 24px;
}

.footerDivider {
    width: 92%;
    margin-left: 4%;
    height: 1px;
    background-image: linear-gradient(to right, #ffffff00 0%, #ffffff98 50%, #ffffff00);
    margin-bottom: 20px;
}

.footerMembers {
    display: flex;
    flex-direction: row;
    width: 88%;
    margin-left: 6%;
    height: 140px;
}

.footerMember {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.footerCompanyRow {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.footerSection {
    padding: 1px;
    display: flex;
    flex-direction: row;
}

.footerSymbol {
    color: #ffffffc4;
    font-size: 16px;
    margin-right: 5px;
}

.footerText {
    color: #ffffffc4;
    font-size: 16px;
}

.footerPlus {
    color: #ffffffc4;
    font-size: 16px;
    margin-top: -2px;
}

.footerLine {
    height: 100px;
    margin-top: 20px;
    width: 1px;
    background-image: linear-gradient(to bottom, #ffffff00 0%, #ffffff98 50%, #ffffff00);
}

@media (max-width: 1100px) {
    .footerMembers {
        display: flex;
        flex-direction: column;
        height: 440px;
    }

    .footerLine {
        display: none;
    }

    .footerMember {
        display: flex;
        flex-direction: column;
        width: 232px;
        margin: auto;
    }
}


/* Home */

.homeImage {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 74vh;
    background-image: 
    linear-gradient(to bottom, #0000005e, #0000006c),
    linear-gradient(to right, #00000065, #00000077),
    url(./images/home-image.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 1px 4px 1px #00000050;
    z-index: 1;
}

.homeImageTitle {
    padding-top: 29vh;
    text-align: center;
    font-size: 46px;
    font-weight: 500;
    color: #FFFFFF;
    z-index: 10;
}

.homeImageLine {
    margin: auto;
    margin-top: 2vh;
    margin-bottom: 2vh;
    width: 610px;
    height: 1px;
    background-color:  #e6e6e6;
    border-radius: 50px;
}

.homeImageSubTitle {
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    color: #FFFFFF;
    z-index: 10;
    margin-left: 40px;
    margin-right: 40px;
}

.homeImageButtons {
    display: flex;
    flex-direction: row;
    margin-top: 7vh;
    z-index: 10;
    align-self: center;
}

.homeImageButtonContainer {
    position: relative;
    margin: auto;
    width: 200px;
}

.homeImageButton {
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 20px;
    font-weight: 500;
    background-color: #336bff;
    color: #ffffff;
    border: solid 1px #336bff;
    z-index: 10;
    width: 140px;
    padding-bottom: 7px;
    padding-top: 7px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 2px;
    outline: none;
}

.homeImageButton:hover {
    background-color: #1313164a;
    color: #ffffff;
    border: solid 1px #ffffff;
    box-shadow: 0px 1px 6px 2px #000000ad;
    cursor: pointer;
}

.homeSection {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding-top: 6vh;
    padding-bottom: 14vh;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 20px 4px #000000d5;
}

/* .homeSectionBackground {
    position: absolute;
    width: 100%;
    height: 100%;
} */

/* .homeUnderUtvikling {
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 1100px;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 1vh;
    margin-top: 1vh;
    align-self: center;
    color: #000000ab;
    line-height: 1.3;
} */

/* .homeInfoSymbol {
    margin: auto;
    margin-right: 10px;
} */

.homeFirstSection {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.homeFirstSectionTitle {
    font-size: 24px;
    font-weight: 450;
    margin: auto;
    margin-top: 2vh;
}

.homeFirstSectionLine {
    margin: auto;
    margin-top: 25px;
    height: 2px;
    width: 100px;
    background-color: #336bff;
}

.homeFirstSectionText {
    margin: auto;
    width: 70%;
    max-width: 870px;
    min-width: 200px;
    margin-top: 4vh;
    font-size: 20px;
    color: #000000;
    font-weight: 400;
    line-height: 1.6;
}

.homeButtonDiv{
    margin: auto;
    margin-top: 5vh;
}

.homeButton {
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 20px;
    background-image: linear-gradient(to bottom right, #082a42, #011320);
    color: #ffffff;
    border: none;
    border-radius: 6px;
    padding-bottom: 8px;
    padding-top: 6px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 400;
    outline: none;
}

.homeButton:hover {
    background-image: linear-gradient(to bottom right, #062135, #010e17);
    box-shadow: 0px 1px 4px 1px #00000072;
    cursor: pointer;
}



.homeSecondSection {
    width: 100%;
    margin-top: 8vh;
    display: flex;
    flex-direction: column;
    background-color: #eff0f3;
    padding-bottom: 8vh;
    margin-bottom: 6vh;
}


.homeCard {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-top: 8vh;
    width: 80%;
    max-width: 1400px;
    box-shadow: 0px 1px 6px 1px #00000022;
}

.homeFirstImage {
    width: 60%;
    /* height: 60vh; */
    background-image: url(./images/home-third-image.jpg);
    /* linear-gradient(to top right, #13779900 60%, #13779996),
    linear-gradient(to top left, #13779900 60%, #13779996),
    linear-gradient(to right, #00000000 50%, #00000028), */
    background-repeat: no-repeat;
    background-size: cover;
}

.homeBox {
    display: flex;
    flex-direction: column;
    width: 40%;
    /* height: 68vh; */
    margin: auto;
    background-color: #ffffff;
    min-height: 62svh;
}

.homeBoxHeader {
    font-size: 24px;
    font-weight: 450;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 6vh;
}

.homeBoxText {
    width: 80%;
    margin-left: 10%;
    margin-top: 5vh;
    font-size: 17px;
    color: #000000;
    line-height: 1.4;
}

.homeCardButtonDiv {
    margin: auto;
    margin-top: 5vh;
    margin-bottom: 5vh;
    margin-left: 10%;
}

.homeSecondImage {
    position: relative;
    width: 60%;
    /* height: 60vh; */
    background-image: url(./images/home-second-image.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 1100px) {
    .homeCardButtonDiv {
        margin: auto;
        margin-top: 5vh;
        margin-bottom: 5vh;
        max-width: 90%;
    }
}

@media (max-width: 900px) {
    .homeImageTitle {
        padding-top: 21vh;
        font-size: 40px;
        font-weight: 500;
    }

    .homeImageButtonContainer {
        position: relative;
        margin: auto;
        width: 160px;
    }

    .homeCard {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 6vh;
    }

    .homeFirstImage {
        width: 100%;
        height: 60vh;
    }
    
    .homeSecondImage {
        width: 100%;
        height: 60vh;
    }

    .homeCardButtonDiv {
        margin: auto;
        margin-top: 3vh;
        margin-bottom: 3vh;
        max-width: 90%;
    }
    
    .homeBox {
        width: 100%;
        height: auto;
        padding-bottom: 5vh;
        min-height: 0vh;
    }

    .homeBoxHeader {
        margin: auto;
        margin-top: 5vh;
    }
    
    .homeBoxText {
        width: 80%;
        margin-left: 10%;
        margin-top: 4vh;
        margin-bottom: 4vh;
    }
}

@media (max-width: 650px) {
    
    .homeImage {
        width: 200%;
        margin-left: -50%;
        height: 70vh;
    }

    .homeImageSubTitle {
        text-align: center;
        font-size: 22px;
        font-weight: 400;
        color: #FFFFFF;
        z-index: 10;
        margin: auto;
        width: 40%;
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .homeImageLine {
        margin: auto;
        margin-top: 2vh;
        margin-bottom: 2vh;
        width: 330px;
        height: 1px;
        background-color:  #e6e6e6;
        border-radius: 50px;
    }
}


/* Kjøp Fond */

.kjopFondImage {
    position: relative;
    width: 100%;
    height: 55vh;
    background-image: 
    linear-gradient(to bottom, #000000c2, #000000c2),
    url(./images/kjop-fond-image.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 3px 8px 0px #000000c0;
}

.kjopFondImageTitle {
    position: relative;
    text-align: center;
    color: #FFFFFF;
    font-size: 40px;
    font-weight: 500;
    margin-top: 22vh;
}

.kjopFondImageLine {
    position: relative;
    background-color: #e6e6e6;
    margin: auto;
    width: 350px;
    height: 1px;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
}

.kjopFondImageSubTitle {
    position: relative;
    text-align: center;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 10px;
}

.kjopFondSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
}

.kjopFondIntroHeader {
    font-size: 30px;
    align-self: center;
    width: 80%;
    max-width: 900px;
    margin-top: 12vh;
    font-weight: 500;
}

.kjopFondIntroText {
    font-size: 20px;
    align-self: center;
    width: 80%;
    max-width: 900px;
    margin-top: 2vh;
    margin-bottom: 8vh;
    line-height: 1.3;
}

.kjopFondSvg {
    width: 100%;
    height: 8vh;
}

.kjopFondKjopSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    background-color: #131316;
    background-color: #131316;
    padding-bottom: 16vh;
}

.kjopFondSelgSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
}

.kjopFondKjopHeader {
    font-size: 36px;
    font-weight: 500;
    align-self: center;
    width: 80%;
    max-width: 900px;
    color: #FFFFFF;
    margin-top: 14vh;
}

.kjopFondKjopText {
    font-size: 20px;
    align-self: center;
    width: 80%;
    max-width: 900px;
    color: #FFFFFF;
    margin-top: 2vh;
    line-height: 1.3;
}

.kjopFondSelgHeader {
    font-size: 36px;
    font-weight: 500;
    align-self: center;
    width: 80%;
    max-width: 900px;
    margin-top: 12vh;
}

.kjopFondSelgText {
    font-size: 20px;
    align-self: center;
    width: 80%;
    max-width: 900px;
    margin-top: 2vh;
    line-height: 1.3;
}

.kjopFondSpace {
    height: 12vh;
}

.kjopFondSelgKontaktLink {
    text-decoration: none;
    color: #0062f5;
    font-weight: 500;
}

.kjopFondSelgKontaktLink:hover {
    text-decoration: none;
    color: #003688;
    font-weight: 500;
}

.kjopFondKjopKontaktLink {
    text-decoration: none;
    color: #8cbaff;
    font-weight: 500;
}

.kjopFondKjopKontaktLink:hover {
    text-decoration: none;
    color: #267dff;
    font-weight: 500;
}


/* Avkastning */

.avkastningImage {
    position: relative;
    width: 100%;
    height: 55vh;
    background-image: 
    linear-gradient(to bottom, #000000b2, #000000b2),
    url(./images/avkastning-image.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 3px 8px 0px #000000b2;
}

/* .avkastningImageBackground {
    position: absolute;
    width: 100%;
    height: 55vh;
    background-color: #131316;
    z-index: -1;
} */

.avkastningImageTitle {
    position: relative;
    text-align: center;
    color: #FFFFFF;
    font-size: 40px;
    font-weight: 500;
    margin-top: 22vh;
}

.avkastningImageLine {
    position: relative;
    background-color: #e6e6e6;
    margin: auto;
    width: 350px;
    height: 1px;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
}

.avkastningImageSubTitle {
    position: relative;
    text-align: center;
    color: #FFFFFF;
    font-size: 22px;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 10px;
}

.avkastningFirstSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 45vh;  /* height: 10vh; */
    background-image: linear-gradient(to bottom right, #f5f6fa, #eaecf5);
}

.avkastningTable {
    display: flex;
    flex-direction: row;
    width: 80%;
    max-width: 1100px;
    height: 160px;
    margin: auto;
    margin-top: -10vh;
    background-image: linear-gradient(to bottom right, #ffffff, #ffffff);
    border-radius: 0px 24px 0px 24px;
    box-shadow: 0px 1px 6px 2px #14161b41;
    z-index: 10;
    padding-right: 1%;
    padding-left: 1%;
}

.avkastningColumn {
    width: 18%;
    height: 100px;
    display: flex;
    flex-direction: column;
    margin: auto;
    background-color: #ffffff;
    border-radius: 0px 8px 0px 8px;
    box-shadow: 0px 1px 3px 1px #0000001f;
}

.avkastningText {
    margin: auto;
    margin-top: 0;
    width: 100%;
    height: 30px;
    padding-top: 8px;
    text-align: center;
    font-size: 18px;
    background-image: linear-gradient(to bottom right, #1f1f23, #121215);
    color: #ffffff;
    border-radius: 0px 8px 0px 0px;
}

.avkastningValue {
    display: flex;
    flex-direction: row;
    margin: auto;
}

.avkastningLoggInnText {
    width: 88%;
    font-size: 20px;
    text-align: center;
    margin: auto;
    margin-top: 0vh;
}

.avkastningLoggInnLink {
    text-decoration: none;
    color: #3284ff;
}

.avkastningLoggInnLink:hover {
    text-decoration: none;
    color: #0056d8;
    font-weight: 500;
}


.avkastningsGraf {
    width: 80%;
    height: 160vh;
    background-image: linear-gradient(to bottom right, #fafafa, #ffffff 50%);
}




/* Om Oss */

.omOssImage {
    position: relative;
    width: 100%;
    height: 55vh;
    background-image: 
    linear-gradient(to bottom, #000000a9, #000000a9),
    url(./images/om-oss-image.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 3px 8px 0px #0000009c;
}

.omOssImageTitle {
    position: relative;
    text-align: center;
    color: #FFFFFF;
    font-size: 40px;
    font-weight: 500;
    margin-top: 22vh;
}

.omOssImageLine {
    position: relative;
    background-color: #e6e6e6;
    margin: auto;
    width: 350px;
    height: 1px;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
}

.omOssImageSubTitle {
    position: relative;
    text-align: center;
    color: #FFFFFF;
    font-size: 22px;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 10px;
}

.omOssFirstSection {
    width: 100%;
    height: 45vh;
}

.omOssUnderUtvikling {
    width: 70%;
    max-width: 1200px;
    margin-left: 15%;
    font-size: 22px;
    font-weight: 500;
    margin-top: 20vh;
    text-align: center;
}


/* Strategi */

.strategiImage {
    position: relative;
    width: 100%;
    height: 55vh;
    background-image: 
    linear-gradient(to bottom, #000000bd, #000000bd),
    url(./images/strategi-image.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 3px 8px 0px #0000009c;
}

.strategiImageTitle {
    position: relative;
    text-align: center;
    color: #FFFFFF;
    font-size: 40px;
    font-weight: 500;
    margin-top: 22vh;
}

.strategiImageLine {
    position: relative;
    background-color: #e6e6e6;
    margin: auto;
    width: 350px;
    height: 1px;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
}

.strategiImageSubTitle {
    position: relative;
    text-align: center;
    color: #FFFFFF;
    font-size: 22px;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 10px;
}

.strategiFirstSection {
    width: 100%;
    height: 45vh;
}

.strategiUnderUtvikling {
    width: 70%;
    max-width: 1200px;
    margin-left: 15%;
    font-size: 22px;
    font-weight: 500;
    margin-top: 20vh;
    text-align: center;
}


/* Kontakt */

.kontaktImage {
    position: relative;
    width: 100%;
    height: 55vh;
    background-image: 
    linear-gradient(to right, #000000c5, #000000c5),
    url(./images/kontakt-image.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 3px 8px 0px #0000009c;
}

.kontaktImageTitle {
    position: relative;
    text-align: center;
    color: #FFFFFF;
    font-size: 40px;
    font-weight: 500;
    margin-top: 22vh;
}

.kontaktImageLine {
    position: relative;
    background-color: #e6e6e6;
    margin: auto;
    width: 350px;
    height: 1px;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
}

.kontaktImageSubTitle {
    position: relative;
    text-align: center;
    color: #FFFFFF;
    font-size: 22px;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 10px;
}

.kontaktFirstSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
}

.kontaktIntroHeader {
    font-size: 30px;
    align-self: center;
    width: 80%;
    max-width: 900px;
    margin-top: 12vh;
    font-weight: 500;
}

.kontaktIntroText {
    font-size: 20px;
    align-self: center;
    width: 80%;
    max-width: 900px;
    margin-top: 2vh;
    margin-bottom: 8vh;
    line-height: 1.3;
}

.kontaktInformasjon {
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-top: 20px;
}

.kontaktSymbol {
    margin-right: 8px;
    margin-top: -1px;
}

.kontaktEmailSymbol {
    margin-right: 8px;
}

.kontaktPlus {
    margin-top: -1px;
}

.kontaktFaqHeader {
    font-size: 30px;
    align-self: center;
    width: 80%;
    max-width: 900px;
    margin-top: 12vh;
    font-weight: 500;
    color: #FFFFFF;
}

.kontaktFaqSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    background-color: #131316;
}

.kontaktFaq {
    width: 80%;
    max-width: 900px;
    min-height: 500px;
    margin: auto;
    font-size: 20px;
    padding-top: 4vh;
    padding-bottom: 8vh;
}

.kontaktAccordionQuestion {
    color: #000000;
    font-weight: 500;
    text-align: left;
    width: 95%;
}

.kontaktAccordionAnswer {
    color: #000000a9;
    line-height: 1.3;
}

.kontaktTilbakemeldingerSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    padding-bottom: 12vh;
}

.kontaktTilbakemeldingerHeader {
    font-size: 30px;
    align-self: center;
    width: 80%;
    max-width: 900px;
    margin-top: 12vh;
    font-weight: 500;
}

.kontaktTilbakemeldingerText {
    font-size: 20px;
    align-self: center;
    width: 80%;
    max-width: 900px;
    margin-top: 2vh;
    margin-bottom: 1vh;
}

.kontaktTilbakemelding {
    display: flex;
    flex-direction: row;
    margin-top: 2vh;
}





/* Logg Inn */

.loggInnBackground {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100vh;
    background-image:
    linear-gradient(to bottom, #13131600 80%, #131316),
    linear-gradient(to bottom right, #070d12cc, #070d12cc),
    url(./images/logg-inn-image.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.signInContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.signInBox {
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 500px;
    margin: auto;
    margin-top: 19vh;
    background-color: white;
    /* background-image: linear-gradient(to bottom right, #f6f7fa, #f5f6f9); */
    color: #000000;
    border-radius: 6px;
    box-shadow: 0px 0px 8px 3px #151518;
}

.signInTitle {
    text-align: center;
    height: 30px;
    margin-top: 20px;
    font-size: 22px;
    font-weight: 500;
    color: #020a0f;
}

.signInForm {
    width: 420px;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    font-size: 17px;
    margin-top: 10px;
    font-weight: 500;
}

.signInField {
    font-size: 17px;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 30px;
    border-radius: 2px;
    border: solid 1px #011320e1;
    background-image: linear-gradient(to bottom right, #f8f9fc, #f7f8fb);
    box-shadow: 1px 1px 4px 2px #cfd2dc3e;
}

.signInButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;
    padding-top: 2px;
    padding-bottom: 4px;
    border-radius: 6px;
    /* background-color: #011320; */
    background-image: linear-gradient(to bottom right, #082a42, #011320);
}

.signInButton:hover {
    background-color: #00070c;
    box-shadow: 0px 0px 4px 2px #0113204b;
    background-image: linear-gradient(to bottom right, #051d2e, #010f1a);
    cursor: pointer;
}

.signInSubmit {
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    height: 40px;
    border-radius: 16px;
    background-color: #00000000;
    color: #ffffff;
    outline: none;
    border: none;
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    z-index: 1;
}

.signInSubmit:hover {
    cursor: pointer;
}

.signInIcon {
    margin-left: -50.5px;
    margin-top: 3px;
}

.signInLink {
    width: 160px;
    margin-left: 170px;
    margin-top: 25px;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #011320;
}

.signInLink:hover {
    font-weight: 500;
    cursor: pointer;
}

.resetPasswordIcon {
    margin-left: -44px;
    margin-top: 8px;
}

.danger {
    display: flex;
    flex-direction: row;
    height: 27px;
    padding-top: 4px;
    padding-right: 10px;
    margin: auto;
    margin-top: 8px;
    margin-bottom: 0px;
    text-align: center;
    font-size: 18px;
    /* background-color: #fc4040; */
    background-image: linear-gradient(to bottom right, #ff2828, #ce1f1f);
    color: #ffffff;
    border-radius: 20px;
    font-weight: 400;
}

.errorIcon {
    margin-left: 1px;
    margin-right: 7px;
    margin-top: -4px;
}

.success {
    display: flex;
    flex-direction: row;
    height: 27px;
    padding-top: 4px;
    padding-right: 10px;
    margin: auto;
    margin-top: 8px;
    margin-bottom: 0px;
    text-align: center;
    font-size: 18px;
    /* background-color: #137899; */
    background-image: linear-gradient(to bottom right, #082a42, #011320);
    color: #ffffff;
    border-radius: 20px;
    font-weight: 400;
}

.emptyBox {
    height: 39px;
}

.signInEllerLineSection {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-left: 10%;
}

.signInEllerLine {
    width: 40%;
    height: 1px;
    background-color: #727272;
    margin: auto;
}

.signInEller {
    font-size: 16px;
    color: #727272;
    margin: auto;
    margin-top: -4px;
}

.newUserButton {
    display: flex;
    flex-direction: row;
    width: 420px;
    margin-left: 40px;
    height: 40px;
    padding-top: 1.5px;
    padding-bottom: 3.5px;
    border-radius: 6px;
    /* background-color: #011320; */
    background-image: linear-gradient(to bottom right, #082a42, #011320);
}

.newUserButton:hover {
    background-color: #00070c;
    box-shadow: 0px 0px 4px 2px #0113204b;
    background-image: linear-gradient(to bottom right, #051d2e, #010f1a);
    cursor: pointer;
}

.newUserButtonText {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    margin: auto;
}

.newUserIcon {
    position: absolute;
    margin-top: 5px;
    margin-left: 367px;
}

.resetPasswordButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;
    padding-top: 2px;
    padding-bottom: 4px;
    border-radius: 6px;
    margin-top: 40px;
    /* background-color: #011320; */
    background-image: linear-gradient(to bottom right, #082a42, #011320);
}

.resetPasswordButton:hover {
    background-color: #00070c;
    box-shadow: 0px 0px 4px 2px #0113204b;
    background-image: linear-gradient(to bottom right, #051d2e, #010f1a);
    cursor: pointer;
}

.signInResetPasswordSpace {
    height: 108px;
}


@media (max-width: 700px) {
    .signInBox {
        width: 80%;
        height: 488px;
    }
    
    .signInTitle {
        margin-top: 10px;
    }
    
    .signInForm {
        width: 80%;
        margin-left: 10%;
    }
    
    .signInLink {
        width: 100%;
        margin-left: 0;
    }
    
    .newUserButton {
        width: 80%;
        margin-left: 10%;
    }
    
    .newUserButtonText {
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        margin: auto;
    }
    
    .newUserIcon {
        position: absolute;
        margin-top: 5px;
        margin-left: 55%;
    }

    .signInResetPasswordSpace {
        height: 106px;
    }
}




/* Min Side */

.minSideLoadingPage {
    width: 100%;
    height: 110vh;
    background-color: #ffffff;
}

.minSideSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    color: #000000;
}

.minSideWelcome {
    margin-top: 12vh;
    margin-bottom: 2vh;
    font-size: 36px;
    font-weight: 500;
    text-align: center;
}

.minSideBeholdningRow {
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 100%;
    height: 400px;
    margin-top: 7vh;
    max-width: 1350px;
    text-align: center;
}

.minSideBeholdningColumn {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 360px;
    margin: auto;
    box-shadow: 0px 1px 8px 0px #00000042;
    border-radius: 6px;
    padding-bottom: 12px;
}

.minSideInnskuddSymbol {
    width: 90px;
    height: 66px;
    background-image: linear-gradient(to bottom right, #ee2076, #8a1848);
    z-index: 1;
    margin-top: -18px;
    margin-left: 20px;
    padding-top: 18px;
    box-shadow: 0px 1px 12px 0px #00000042;
    border-radius: 6px;
}

.minSideBeholdningSymbol {
    width: 90px;
    height: 66px;
    background-image: linear-gradient(to bottom right, #ffce48, #ec872e);
    z-index: 1;
    margin-top: -18px;
    margin-left: 20px;
    padding-top: 18px;
    box-shadow: 0px 1px 12px 0px #00000042;
    border-radius: 6px;
}

.minSideAvkastningSymbol {
    width: 90px;
    height: 66px;
    background-image: linear-gradient(to bottom right, #19d46d, #04803c);
    z-index: 1;
    margin-top: -18px;
    margin-left: 20px;
    padding-top: 18px;
    box-shadow: 0px 1px 12px 0px #00000042;
    border-radius: 6px;
}

.minSideInnskuddHeader {
    width: 265px;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 36px;
    color: #FFFFFF;
    background-image: linear-gradient(to bottom right, #091c29, #011320);
    padding-left: 95px;
    margin-top: -66px;
    padding-top: 11px;
    padding-bottom: 11px;
    border-radius: 6px 6px 0px 0px;
}

.minSideBeholdningHeader {
    width: 290px;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 36px;
    color: #FFFFFF;
    background-image: linear-gradient(to bottom right, #091c29, #011320);
    padding-left: 70px;
    margin-top: -66px;
    padding-top: 11px;
    padding-bottom: 11px;
    border-radius: 6px 6px 0px 0px;
}

.minSideAvkastningHeader {
    width: 290px;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 36px;
    color: #FFFFFF;
    background-image: linear-gradient(to bottom right, #091c29, #011320);
    padding-left: 70px;
    margin-top: -66px;
    padding-top: 11px;
    padding-bottom: 11px;
    border-radius: 6px 6px 0px 0px;
}

.minSideBeholdningColumnContent {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    width: 330px;
    margin-left: 15px;
    height: 260px;
}

.minSideQuestion {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 280px;
}

.minSideQuestionDesc {
    position: absolute;
    left: 12px;
}

.minSideQuestionValue {
    position: absolute;
    left: 194px;
    font-weight: 500;
}

.minSideQuestionReturn {
    position: relative;
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 174px;
    font-weight: 500;
    color: #c91414;
}

.minSideAnswer {
    position: relative;
    font-size: 18px;
    color: #000000d0;
    text-align: left;
}

.minSideInnskuddAnswer {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: #000000d0;
    text-align: left;
}

.minSideInnskuddRow {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    height: 18px;
}

.minSideInnskuddDate {
    position: absolute;
}

.minSideInnskuddAmount {
    position: absolute;
    left: 120px;
}

.minSideInnskuddType {
    position: absolute;
    left: 215px;
    color: #000000b2;
}

.minSideBeholdningColumnDate {
    position: relative;
    margin-top: 12px;
    font-size: 18px;
    color: #000000b7;
}

.minSideSubWelcome {
    font-size: 20px;
    font-weight: 500;
    width: 80%;
    max-width: 900px;
    margin: auto;
    margin-top: 2vh;
    text-align: center;
}

.minSideReason {
    display: flex;
    flex-direction: row;
    width: 80%;
    max-width: 900px;
    margin: auto;
    margin-top: 7vh;
}

.minSideReasonSymbol {
    margin: auto;
    margin-left: 2px;
    margin-right: 5px;
}

.minSideReasonTitle {
    font-size: 20px;
    font-weight: 500;
    align-self: center;
}

.minSideReasonText {
    font-size: 20px;
    width: 80%;
    max-width: 900px;
    line-height: 1.3;
    margin: auto;
    margin-top: 1vh;
}

.minSideKontaktLink {
    text-decoration: none;
    color: #0062f5;
    font-weight: 500;
}

.minSideKontaktLink:hover {
    text-decoration: none;
    color: #003688;
    font-weight: 500;
}

.signOutButtonSection {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 200px;
    min-width: 300px;
    margin: auto;
    margin-bottom: 7vh;
}

.signOut {
    align-self: center;
    width: 80%;
    height: 40px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 6px;
    border: none;
    background-image: linear-gradient(to bottom right, #082a42, #011320);
    color: #FFFFFF;
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    outline: none;
    margin-left: 10%;
}

.signOut:hover {
    background-image: linear-gradient(to bottom right, #062033, #010f1a);
    box-shadow: 0px 1px 4px 2px #010f1a28;
    cursor: pointer;
    transition: 300ms;
}

.signOutSymbol {
    margin-top: 5px;
    margin-left: -12%;
}

@media (max-width: 1150px) {
    .minSideBeholdningRow {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 90%;
        height: 100%;
        margin-top: -20px;
        text-align: center;
    }

    .minSideBeholdningColumn {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 360px;
        margin: auto;
        box-shadow: 0px 1px 8px 0px #00000042;
        border-radius: 6px;
        padding-bottom: 10px;
        margin-top: 60px;
    }

    .signOutSymbol {
        margin-top: 5px;
        margin-left: -14%;
    }
}

@media (max-width: 600px) {
    .minSideBeholdningRow {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 96%;
        height: 100%;
        margin-top: -25px;
        text-align: center;
    }

    .minSideBeholdningColumn {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 96%;
        margin: auto;
        box-shadow: 0px 1px 8px 0px #00000042;
        border-radius: 6px;
        padding-bottom: 12px;
        margin-top: 60px;
    }

    .minSideInnskuddHeader {
        width: 84%;
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 36px;
        color: #FFFFFF;
        background-image: linear-gradient(to bottom right, #091c29, #011320);
        padding-left: 16%;
        margin-top: -60px;
        padding-top: 11px;
        padding-bottom: 11px;
        border-radius: 6px 6px 0px 0px;
    }
    
    .minSideBeholdningHeader {
        width: 88%;
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 36px;
        color: #FFFFFF;
        background-image: linear-gradient(to bottom right, #091c29, #011320);
        padding-left: 12%;
        margin-top: -60px;
        padding-top: 11px;
        padding-bottom: 11px;
        border-radius: 6px 6px 0px 0px;
    }
    
    .minSideAvkastningHeader {
        width: 88%;
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 36px;
        color: #FFFFFF;
        background-image: linear-gradient(to bottom right, #091c29, #011320);
        padding-left: 12%;
        margin-top: -60px;
        padding-top: 11px;
        padding-bottom: 11px;
        border-radius: 6px 6px 0px 0px;
    }
    
    .minSideBeholdningColumnContent {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        width: 96%;
        margin-left: 2%;
        margin-bottom: 15px;
        height: auto;
    }

    .minSideQuestion {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 92%;
    }
    
    .minSideQuestionDesc {
        position: absolute;
        left: 0px;
    }
    
    .minSideQuestionValue {
        position: absolute;
        left: 70%;
        font-weight: 500;
    }
    
    .minSideInnskuddDate {
        position: absolute;
        left: 0px;
    }
    
    .minSideInnskuddAmount {
        position: absolute;
        left: 60%;
    }
    
    .minSideInnskuddType {
        position: absolute;
        left: 110%;
        color: #000000b2;
    }

    .minSideQuestionReturn {
        position: relative;
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 65%;
        font-weight: 500;
        color: #c91414;
    }

    .minSideInnskuddSymbol {
        width: 70px;
        height: 60px;
        background-image: linear-gradient(to bottom right, #ee2076, #8a1848);
        z-index: 1;
        margin-top: -18px;
        margin-left: 10px;
        padding-top: 10px;
        box-shadow: 0px 1px 12px 0px #00000042;
        border-radius: 6px;
    }
    
    .minSideBeholdningSymbol {
        width: 70px;
        height: 60px;
        background-image: linear-gradient(to bottom right, #ffce48, #ec872e);
        z-index: 1;
        margin-top: -18px;
        margin-left: 10px;
        padding-top: 10px;
        box-shadow: 0px 1px 12px 0px #00000042;
        border-radius: 6px;
    }
    
    .minSideAvkastningSymbol {
        width: 70px;
        height: 60px;
        background-image: linear-gradient(to bottom right, #19d46d, #04803c);
        z-index: 1;
        margin-top: -18px;
        margin-left: 10px;
        padding-top: 10px;
        box-shadow: 0px 1px 12px 0px #00000042;
        border-radius: 6px;
    }
}

@media (max-width: 500px) {
    .minSideSubWelcome {
        font-size: 20px;
        font-weight: 500;
        width: 80%;
        max-width: 900px;
        margin: auto;
        margin-top: 2vh;
        text-align: left;
    }
}


/* Admin */

.adminContainer {
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #394058;
}

.adminSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 140px;
}

.adminTopSection {
    position: fixed;
    z-index: 5;
    width: 100%;
    height: 140px;
    background-color: #131316;
}

.adminTopLine {
    position: fixed;
    z-index: 100;
    width: 100%;
    margin-top: 140px;
    height: 2px;
}

.adminNavigation {
    width: 740px;
    display: flex;
    flex-direction: row;
    margin: auto;
}

.adminNavigationHighlight {
    width: 740px;
    display: flex;
    flex-direction: row;
    margin: auto;
    height: 16px;
}

.adminNavigationHighlightTab {
    width: 150px;
    height: 7px;
    background-color: #ffffff;
    margin: auto;
    border-radius: 2px;
}

.adminTab {
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    margin: auto;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    width: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 80px;
    text-align: center;
    background-color: #394058;
    border-radius: 4px 4px 4px 4px;
    border: none;
    outline: none;
}

.adminTab:hover {
    background-color: #3c486e;
    cursor: pointer;
}

.adminMessage {
    position: absolute;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin-top: 156px;
}

.kapitalSection {
    display: flex;
    flex-direction: row;
    width: 900px;
    margin: auto;
    margin-top: 7vh;
}

.kapitalBox {
    display: flex;
    flex-direction: column;
    width: 370px;
    background-color: #131316;
    margin: auto;
    padding-bottom: 6px;
    border-radius: 12px 12px 12px 12px;
    box-shadow: #0000003f 2px 2px 8px 2px;
}

.kapitalHeader {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    padding-top: 16px;
    padding-bottom: 10px;
    background-color: #131316;
    color: #ffffff;
    border-radius: 12px 12px 0px 0px;
}

.kapitalFirstRow {
    position: relative;
    display: flex;
    font-size: 18px;
    flex-direction: row;
    width: 260px;
    height: 32px;
    padding-top: 10px;
    color: #ffffff;
    margin: auto;
    margin-top: 9px;
    margin-bottom: 2px;
    border-radius: 6px;
}

.kapitalSecondRow{
    position: relative;
    display: flex;
    font-size: 18px;
    flex-direction: row;
    width: 200px;
    height: 32px;
    padding-top: 10px;
    color: #ffffff;
    margin: auto;
    margin-top: 3px;
    margin-bottom: 7px;
    border-radius: 6px;
}

.kapitalInput {
    height: 29px;
    width: 120px;
    padding-left: 10px;
    font-size: 18px;
    margin: auto;
    margin-top: -6px;
    border: none;
}

.kapitalUpdateButton {
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 5px;
    background-color: #d11b67;
    border: none;
    border-radius: 4px;
    outline: none;
    margin: auto;
    margin-top: -6px;
}

.kapitalUpdateButton:hover {
    background-color: #970e47;
    cursor: pointer;
}

.kapitalReturn {
    font-size: 22px;
    font-weight: 400;
    color: #19d46d;
    margin: auto;
    width: 100px;
}

.kapitalReturnIcon {
    height: 30px;
    width: 30px;
    border-radius: 30px;
    margin: auto;
}

.kapitalReturnIcon:hover {
    background-color: #3c486e;
}

.kapitalRow {
    position: relative;
    display: flex;
    font-size: 18px;
    flex-direction: row;
    width: 350px;
    height: 32px;
    padding-top: 10px;
    background-image: linear-gradient(to left, #131316, #2a2e3b);
    margin: auto;
    margin-top: 12px;
    margin-bottom: 8px;
    border-radius: 6px;
}

.kapitalRowDescription {
    position: absolute;
    left: 15px;
    color: #ffffff;
}

.kapitalRowValue {
    position: absolute;
    left: 246px;
    color: #ffffff;
}

.kapitalDato {
    display: flex;
    flex-direction: row;
    position: relative;
    margin: auto;
    margin-top: 8vh;
    font-size: 20px;
    color: #ffffff;
}



.eierandelerTable {
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-top: 7vh;
    background-color: #131316;
    font-size: 16px;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    color: #ffffff;
}

.eierandelerFirstRow {
    display: flex;
    flex-direction: row;
    margin: auto;
    padding-left: 12px;
    padding-top: 8px;
    padding-bottom: 6px;
}

.eierandelerRow {
    display: flex;
    flex-direction: row;
    margin: auto;
    padding-left: 12px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 6px;
    background-color: #20222b;
    border-radius: 4px;
}

.eierandelerNavn {
    width: 180px;
}

.eierandelerBeholdning {
    width: 150px;
}

.eierandelerEierandel {
    width: 180px;
}

.eierandelerInnskudd {
    width: 150px;
}

.eierandelerAvkastningDesc {
    width: 150px;
}

.eierandelerAvkastning {
    width: 150px;
    color: #19d46d;
}

.eierandelerId {
    width: 310px;
}



/* .honorarTitle {
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin-top: 30px;
}

.honorarTable {
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-top: 3vh;
    background-color: #131316;
    font-size: 16px;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    color: #ffffff;
}

.honorarFirstRow {
    display: flex;
    flex-direction: row;
    margin: auto;
    padding-left: 12px;
    padding-top: 8px;
    padding-bottom: 6px;
}

.honorarRow {
    display: flex;
    flex-direction: row;
    margin: auto;
    padding-left: 12px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 6px;
    background-color: #20222b;
    border-radius: 4px;
}

.honorarNavn {
    width: 180px;
}

.honorarAvkastning {
    width: 150px;
}

.honorarHonorarProsent {
    width: 150px;
}

.honorarHonorarBeløp {
    width: 180px;
}

.honorarBeholdningFør {
    width: 180px;
}

.honorarBeholdningEtter {
    width: 180px;
} */




.innskuddSection {
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-top: 7vh;
}

.addInnskuddBox {
    display: flex;
    flex-direction: column;
    margin: auto;
    border-radius: 12px;
    background-color: #131316;
    color: #ffffff;
    font-size: 18px;
    padding: 12px;
    padding-top: 0px;
    padding-left: 32px;
    width: 800px;
}

.addInnskuddHeader {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 20px;
}

.addInnskuddRow {
    display: flex;
    flex-direction: row;
    margin-top: 4px;
}

.addInnskuddEntry {
    width: 200px;
}

.addInnskuddInput {
    font-size: 18px;
    width: 160px;
    margin-bottom: 10px;
}

.addInnskuddUser {
    width: 400px;
}

.addInnskuddUserInput {
    font-size: 18px;
    width: 360px;
} 

.addInnskuddButton {
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    border: none;
    outline: none;
    background-color: #288bdb;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    border-radius: 4px;
    width: 170px;
    margin-top: -12px;
    margin-bottom: 30px;
    height: 40px;
}

.addInnskuddButton:hover {
    background-color: #1f6aa8;
    cursor: pointer;
}

.addInnskuddLastRow {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    margin-bottom: 8px;
    width: 240px;
    margin-left: 54px;
}

.addTransactionButton {
    margin: auto;
    border: none;
    outline: none;
    border-radius: 4px;
    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
    color: #ffffff;
    background-color: #333644;
}

.addTransactionButton:hover {
    background-color: #19d46d;
    cursor: pointer;
}

.removeTransactionButton {
    margin: auto;
    border: none;
    outline: none;
    border-radius: 4px;
    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
    color: #ffffff;
    background-color: #333644;
}

.removeTransactionButton:hover {
    background-color: #d11b67;
    cursor: pointer;
}


.backupSection {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 7vh;
}

.backupBox {
    display: flex;
    flex-direction: column;
    margin: auto;
    border-radius: 12px;
    background-color: #131316;
    color: #ffffff;
    font-size: 18px;
    width: 700px;
    margin-bottom: 20px;
}

.backupHeader {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 12px;
}

.backupRow {
    display: flex;
    flex-direction: row;
}

.backupSecondRow {
    display: flex;
    flex-direction: row;
    width: 540px;
    margin: auto;
}

.backupColumn {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: auto;
    margin-top: 20px;
}

.backupDesc {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.backupWarning {
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-top: 20px;
}

.backupButton {
    margin: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 2px;
    border-radius: 12px;
    background-color: #ffce48;
}

.backupButton:hover {
    background-color: #bb8e11;
    cursor: pointer;
}

.backupConfirmButton {
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    border: none;
    outline: none;
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    border-radius: 4px;
    width: 170px;

    margin: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 12px;
    background-color: #19d46d;
}

.backupConfirmButton:hover {
    background-color: #0b974a;
    cursor: pointer;
}

.backupCancelButton {
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    border: none;
    outline: none;
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    border-radius: 4px;
    width: 170px;

    margin: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 12px;
    background-color: #d11b67;
}

.backupCancelButton:hover {
    background-color: #8f1145;
    cursor: pointer;
}

.adminLogo {
    position: fixed;
    margin-left: 5%;
    margin-top: 92px;
    color: #ffffff;
    z-index: 20;
    font-size: 18px;
}

.adminSignOut {
    position: fixed;
    display: flex;
    flex-direction: row;
    text-align: center;
    background-color: #394058;
    color: #ffffff;
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    outline: none;
    font-size: 18px;
    font-weight: 400;
    border-radius: 24px;
    margin-top: 84px;
    right: 4.2%;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 8px;
    padding-top: 8px;
    border: none;
    z-index: 20;
}

.adminSignOut:hover {
    background-color: #3c486e;
    box-shadow: 0px 3px 8px 0px #0000003b;
    cursor: pointer;
}